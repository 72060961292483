#view_institutional {
    
  background-color: $color-white;
  
  .menu {

    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $color-ice;
    background-color: $color-white;
    
    @include media-breakpoint-up(xs) {
      height: auto;
      padding: 5px 0;
    }
    
    @include media-breakpoint-up(md) {
      height: 100px;
    }

    ._med-container {

      width: $percent-100;

      ._btn-menu {

        border: 0;
        width: $percent-100;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px;
        border-radius: 6px;
        background-color: $color-white;
        
        @include media-breakpoint-up(xs) {
          display: flex;
        }
        
        @include media-breakpoint-up(md) {
          display: none;
        }

        span {
          font-weight: $fw700;
          color: $color-black;
          font-size: 13px;
        }

        svg {
          width: auto;
          height: 20px;
        }
      }

      ._nav {

        width: $percent-100;

        @include media-breakpoint-up(md) {
          display: flex !important;
          align-items: center;
          justify-content: center;
        }

        ul {

          display: flex;
          align-items: center;
          justify-content: center;

          @include media-breakpoint-up(xs) {
            flex-direction: column;
          }

          @include media-breakpoint-up(md) {
            flex-direction: row;
          }

          li {

            @include media-breakpoint-up(md) {
              margin-left: 40px;
            }

            &:first-child {
              margin-left: 0;
            }

            a {
              color: $color-black;
              font-weight: $fw700;
              font-size: 14px;

              @include media-breakpoint-up(xs) {
                display: inline-block;
                padding: 10px 0;
              }
            }
            
          }

        }

      }

    }

  }

  .point1 {

    ._med-container {

      text-align: center;

      .headline {
        display: block;
        text-align: center;
      }

      ._button {
        
        a {

          @include media-breakpoint-up(xs) {
            height: 38px;
            font-size: 14px;
          }
          
          @include media-breakpoint-up(lg) {
            height: 42px;
            font-size: 14px;
          }

        }
      }

    }

  }

  .point2 {

    ._med-container {
      
      hgroup._block-headline {

        h1, h2 {
          color: $color-white;
        }

      }

    }

  }

  .point3 {

    ._med-container {

      .image-gallery-content {

        max-width: 680px;
        width: $percent-100;
        margin: 0 auto;

        .image-gallery-icon.image-gallery-left-nav,
                .image-gallery-icon.image-gallery-right-nav {
                    svg {
                        width: 40px;
                        height: auto;
                    }

                    &:hover {
                        color: $color-red;
                    }
                }

                .image-gallery-bullets {
                    bottom: -24px !important;
                    display: block !important;

                    .image-gallery-bullets-container {

                        .image-gallery-bullet {
                            background-color: $color-nude;
                            height: 8px;
                            width: 8px;
                            border-color: $color-nude;
                        }
                        
                        .image-gallery-bullet.active {
                            width: 20px;
                            background-color: $color-violet;
                            border-color: $color-violet;
                        }
                    }
                }

      }

    }

  }


  .point4 {

    ._med-container {
      
      hgroup._block-headline {

        h1, h2 {
          color: $color-white;
        }

      }

    }

  }

  // .point1 {
  //   @include media-breakpoint-up(xs) {
  //     padding: 60px 0;
  //   }

  //   @include media-breakpoint-up(md) {
  //     padding: 60px 0;
  //   }
  // }

  .point5,
  .point6 {
    @include media-breakpoint-up(xs) {
      padding: 30px 0;
    }

    @include media-breakpoint-up(md) {
      padding: 60px 0;
    }
  }

  .point1 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(xs) {
      max-height: 478px;
    }

    @include media-breakpoint-up(md) {
      max-height: none;
    }
  }
  
  .point2,
  .point3,
  .point4 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .point1,
  .point2,
  .point3,
  .point4,
  .point5,
  .point6 {
    
    // @include media-breakpoint-up(xs) {
    //   padding: 30px 0;
    // }

    // @include media-breakpoint-up(md) {
    //   padding: 60px 0;
    // }

    ._med-container {

      ._block-headline {
        max-width: 724px;
        margin: 0 auto;
        
        @include media-breakpoint-up(xs) {
          margin-bottom: 15px;
        }

        @include media-breakpoint-up(md) {
          margin-bottom: 30px;
        }

        .headline {
          display: block;
          text-align: center;
          font-weight: $fw700;
          margin-bottom: 5px;
        }

        h2 {
          text-align: center;
          line-height: 1.7;
          margin-top: 15px;
          
          @include media-breakpoint-up(xs) {
            font-size: 12px;
            font-weight: $fw700;
          }
          
          @include media-breakpoint-up(md) {
            font-weight: $fw500;
            font-size: 16px;
          }

          ul {
            li {
              text-align: left;
              margin-bottom: 15px;
            }
          }
        }
      }

      ._button {

        @include media-breakpoint-up(xs) {
          margin-top: 20px;
        }

        @include media-breakpoint-up(md) {
          margin-top: 28px;
        }

        a {
          color: $color-white;
        }
      }
    }
  }

  .with-video {

    ._med-container {

      .video {
        border-radius: 7px;
        overflow: hidden;
        margin: 0 auto;

        position: relative;
        overflow: hidden;
        
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
        
        @include media-breakpoint-up(xs) {
          width: 290px;
          height: 173px;
        }
        
        @include media-breakpoint-up(md) {
          width: 440px;
          height: 257px;
        }
        
        @include media-breakpoint-up(lg) {
          width: 640px;
          height: 360px;
        }

      }

    }

  }

}

// #################################################
// NEWSLETTER
// #################################################

#view_institutional {
		.newsletter {
			._med-container {
				.newsletter-content {
					max-width: 670px;
					margin: 0 auto;
					h1 {
						margin-bottom: 15px;
					}
					#mc_embed_signup {
						.newsletter-form {
							#mc_embed_signup_scroll {
								display: flex;
								align-items: center;
								justify-content: space-between;
								margin-top: 30px;
								.form-group {
									input[type=text] {
										font-family: "Comfortaa";
									}
									input[type=email] {
										font-family: "Comfortaa";
									}
									button[type=submit] {
										font-family: "Comfortaa";
									}
									button[type=button] {
										font-family: "Comfortaa";
									}
									input {
										max-width: 472px;
										min-width: 472px;
										width: 100%;
										border: 1px solid #f2f3f5;
										height: 50px;
										border-radius: 50px;
										font-size: 14px;
										padding: 0 15px;
										outline: 0;
										&:focus {
											outline: 0;
										}
									}
									input[type=submit] {
										display: block;
										height: 50px;
										max-width: 187px;
										min-width: 187px;
										width: 100%;
										background-color: #c2403f;
										border-radius: 50px;
										border: 0;
										color: #fff;
										outline: 0;
										font-weight: 700;
										font-size: 15px;
									}
								}
							}
						}
					}
				}
			}
		}
}
@media (min-width: 240px) {
	#view_institutional {
			.newsletter {
				._med-container {
					.newsletter-content {
						h1 {
							font-size: 26px;
						}
						h2 {
              font-size: 14px;
              line-height: 1.6;
						}
						#mc_embed_signup {
							.newsletter-form {

                                margin-top: 0;
                                
								#mc_embed_signup_scroll {
									flex-direction: column;
									.form-group {
										input {
											min-width: 290px;
											height: 44px;
										}
										input[type=submit] {
											height: 44px;
										}
									}
								}
							}
						}
					}
				}
			}
	}
}
@media (min-width: 992px) {
	#view_institutional {
			.newsletter {
				._med-container {
					.newsletter-content {
						h1 {
							font-size: 30px;
						}
						h2 {
							font-size: 18px;
						}
						#mc_embed_signup {
							.newsletter-form {
								#mc_embed_signup_scroll {
									.form-group {
                                        
										input {
											height: 50px;
										}
										input[type=submit] {
											height: 50px;
										}
                    }

                    .form-group.clear {
                        margin-left: 10px;
                    }
								}
							}
						}
					}
				}
			}
	}
}
@media (min-width: 1200px) {
	#view_institutional {
			.newsletter {
				._med-container {
					.newsletter-content {
						h1 {
							font-size: 36px;
						}
					}
				}
			}
	}
}
@media (min-width: 768px) {
	#view_institutional {
			.newsletter {
				._med-container {
					.newsletter-content {
						#mc_embed_signup {
							.newsletter-form {
								#mc_embed_signup_scroll {
									flex-direction: row;
									.form-group {
										input {
											min-width: 472px;
											height: 48px;
										}
										input[type=submit] {
											height: 48px;
										}
									}
								}
							}
						}
					}
				}
			}
	}
}