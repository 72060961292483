#view_help {
    
    margin-bottom: 30px;
    
    ._med-container {
        
        ._wrap-help-search {
            
            max-width: 430px;
            width: $percent-100;
            margin: 0 auto;
            
            ._title {
                font-weight: $fw700;
                color: $color-black;
                text-align: center;
                line-height: 1.7;
                
                @include media-breakpoint-up(xs) {
                    font-size: 15px;
                    max-width: 220px;
                    margin: 0 auto;
                    margin-bottom: 10px;
                }

                @include media-breakpoint-up(md) {
                    max-width: none;
                    font-size: 18px;
                }
            }
        }
        
        ._help-boxes {
            
            margin: 30px 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            
            @include media-breakpoint-up(xs) {
                flex-direction: column;
            }
            
            @include media-breakpoint-up(md) {
                flex-direction: row;
            }
            
            ._help-box {

                background-color: $color-white;
                -webkit-box-shadow: 0 4px 14px 4px rgba(180,180,180,0.1);
                box-shadow: 0 4px 14px 4px rgba(180,180,180,0.1);
                padding: 15px;
                border-radius: 4px;
                max-width: 420px;
                width: $percent-100;
                min-height: 174px;
                position: relative;
                
                @include media-breakpoint-up(xs) {
                    margin-left: 0;
                    margin-bottom: 15px;
                }

                @include media-breakpoint-up(md) {
                    margin-left: 15px;
                    margin-bottom: 0;
                }
                
                &:first-child {
                    margin-left: 0;
                }
                
                ._box-title {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    flex-direction: row-reverse;
                    font-size: 15px;
                    font-weight: $fw700;
                    color: $color-black;
                    margin-bottom: 20px;
                    
                    button {
                        max-height: none;
                        width: auto;
                        padding: 0.169rem 0.275rem;
                        margin-right: 15px;
                        
                        svg {
                            height: 18px;
                            width: auto;
                            
                            path {
                                fill: $color-gray;
                            }
                        }
                    }
                }
                
                ._box-content {
                    
                    ._text {
                        font-size: 12px;
                        font-weight: $fw400;
                        color: $color-gray;
                        margin-bottom: 20px;
                    }
                    
                    a {
                        font-size: 12px;
                        font-weight: $fw700;
                        color: $color-blue-light;
                        display: inline-block;
                    }
                }
                a {
                    position: absolute;
                    bottom: 15px;
                    left: 15px;
                    font-size: 12px;
                    font-weight: $fw700;
                    color: $color-blue-light;
                    display: inline-block;
                }
                
            }
        }
        
        ._help-content {
            
            background-color: $color-white;
            border: 1px solid $color-ice;
            padding: 20px;
            
            ._title {
                font-weight: $fw700;
                color: $color-black;
                text-align: left;
                line-height: 1.7;
                
                @include media-breakpoint-up(xs) {
                    font-size: 15px;
                    max-width: 220px;
                    margin-bottom: 10px;
                }

                @include media-breakpoint-up(md) {
                    max-width: none;
                    font-size: 18px;
                }
            }
            
            ._wrap-topics {
                
                ._topics {
                        
                    ._topics-list {

                        li {
                            a._item {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                font-weight: $fw700;
                                color: $color-gray-bolder;
                                border-bottom: 1px solid $color-ice;
                                padding: 15px 0;
                                cursor: pointer;
                                
                                ._item-name {
                                    
                                    @include media-breakpoint-up(xs) {
                                        font-size: 13px;
                                    }

                                    @include media-breakpoint-up(md) {
                                        font-size: 15px;
                                    }
                                    
                                }
                                
                                &::after {
                                    content: '';
                                    background-image: url('#{$path-image}/arrow-select-right.png');
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: 8px;
                                    height: 18px;
                                    width: 18px;
                                }
                                
                            }

                            a._item.active { 
                                &::after {
                                    transform: rotate(90deg);
                                }
                             }

                             .faq-accordion {
                                 margin-top: 0;
                                
                                 .MuiAccordionSummary-content {
                                    .MuiTypography-body1 {
                                        font-family: $font-comfortaa;
                                        font-weight: $fw700;
                                        color: $color-gray-bolder;
                                        font-size: 14px !important;
                                    }
                                 }

                                 .MuiAccordionSummary-content.Mui-expanded {
                                     margin-bottom: 5px !important;
                                 }

                                 .MuiAccordionSummary-root {
                                     padding-right: 0 !important;
                                 }
                                 
                                 .MuiCollapse-container {
                                     .MuiCollapse-wrapper {
                                         margin: 0;
                                         .MuiCollapse-wrapperInner {
                                             div {
                                                 .MuiAccordionDetails-root {
                                                     p {
                                                        font-family: $font-comfortaa;
                                                        font-weight: $fw700;
                                                        color: $color-gray-bolder;
                                                        line-height: 1.9;
                                                        font-size: 13px !important;
                                                     }
                                                 }
                                             }
                                         }
                                     }
                                 }
                             }

                             .faq-accordion.MuiPaper-elevation1 {
                                 box-shadow: none !important;
                             }
                             
                             .faq-accordion.MuiAccordion-root:before {
                                 height: 0 !important;
                             }
                            
                        }

                    }
                     
                }
                
            }
            
        }
        
    }
    
}

// Modal Dialog

._paraf-help-modal {
    font-family: $font-comfortaa;
    font-weight: $fw700;
}