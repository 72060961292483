#favorites {
	
	
	._wrap-favorites {
		background-color: $color-white;
		
		._favorites-wrap-list {

			._favorites-list {

				ul {

					li {
						display: flex;
						align-items: center;
						border-top: 1px solid $color-ice;
						padding: 15px 15px;
						overflow: hidden;

						.product {

							display: flex;
							align-items: center;
							justify-content: flex-start;
							width: $percent-100;

							.product-image {
								height: 80px;
								width: 80px;
								min-width: 80px;
								overflow: hidden;
								border: 1px solid $color-ice;
								border-radius: 5px;

								img {
									width: 100%;
									height: auto;
								}
							}

							.product-info {

								margin-left: 15px;

								.product-info-title {
									font-weight: $fw700;
									color: $color-black;
									
									@include media-breakpoint-up(xs) {
										font-size: 13px;
									}

									@include media-breakpoint-up(md) {
										font-size: 14px;
									}
								}

							}

						}

						._actions {
							button {
							  svg {
								width: auto;
								height: 18px;
						
								path {
								  fill: #cccccc;
								}
							  }
							}
						  }
						
						  @include media-breakpoint-up(md) {
							._actions {
							  position: relative;
							  right: -100px;
							  opacity: 0;
							  -webkit-transition: all 0.2s ease-in-out;
							  -moz-transition: all 0.2s ease-in-out;
							  -ms-transition: all 0.2s ease-in-out;
							  -o-transition: all 0.2s ease-in-out;
							  transition: all 0.2s ease-in-out;
							}
						
							&:hover {
							  ._actions {
								display: block;
								right: 0;
								opacity: 1;
								-webkit-transition: all 0.2s ease-in-out;
								-moz-transition: all 0.2s ease-in-out;
								-ms-transition: all 0.2s ease-in-out;
								-o-transition: all 0.2s ease-in-out;
								transition: all 0.2s ease-in-out;
							  }
							}
						  }
					}

				}

			}

		}
	}
	
}