#my-purchases {

	._wrap-title-and-tabs {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include media-breakpoint-up(xs) {
			flex-direction: column;
		}

		@include media-breakpoint-up(md) {
			flex-direction: row;
			margin-bottom: 1px;
		}

		._title-box {
			@include media-breakpoint-up(xs) {
				width: 100%;
			}

			@include media-breakpoint-up(md) {
				width: 50%;
			}
		}
		._material-tabs {
			@include media-breakpoint-up(xs) {
				width: $percent-100;
				border: {
					top: 1px solid $color-ice-bold;
					bottom: 1px solid $color-ice-bold;
				};
			}
			
			@include media-breakpoint-up(md) {
				border: 0;
				width: 50%;
			}
		}
		
		._title-box {
			margin-bottom: 0;
		}
		
	}

	._accordion {
		
		._accordion-content {

			ul._list-content {

				li.flex {

					._item-content {
						display: flex;
						align-items: flex-end;
						justify-content: flex-start;

						._frete {

							font-size: 12px;
							position: relative;
							top: -2px;
							margin-left: 6px;

							svg {

								width: 18px;
								height: auto;
								position: relative;
								top: -1px;
								margin-right: 4px;

								path {
									fill: $color-black;
									opacity: 0.7;
								}
							}
						}
					}
				}
			}
		}
	}

}