#view_buy {
  background-color: $color-white;
  padding-top: 20px;

  // @include media-breakpoint-up(xs)

  // Globals
  form {
    ._med-container {

      @include media-breakpoint-up(xs) {
        padding: 0; 
      }
      
      @include media-breakpoint-up(md) {
        @include padding-lt-rt; 
      }

      ._columns {
        ._column {
          ._block {
            border-bottom: 1px solid $color-ice;

            @include media-breakpoint-up(xs) {
              @include padding-lt-rt;
            }

            @include media-breakpoint-up(md) {
              padding: 0;
            }

            ._block-title {
              font-size: 16px;
              font-weight: $fw400;
              color: $color-blue;
              padding: 0.95rem 0;
              font-family: $font-nunito;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .btn-add-payment {
                font-size: 12px;
                font-weight: $fw700;
                color: $color-white;
                font-family: $font-comfortaa;
                padding: 0.375rem 0.75rem;
                max-width: 122px;

                svg {
                  path {
                    fill: $color-white;
                  }
                }
              }
            }
          }

          .checkout-actions {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
              padding: 15px 0;
            }

            button {
              font-weight: $fw700;

              &:not(:first-child) {
                margin-left: 0;
                margin-top: 8px;
              }
            }

            button.accept {
              background-color: $color-red;
              svg {
                margin-left: 8px;
                path {
                  fill: $color-white;
                }
              }
            }

            button.cancel {
              background-color: #f1f1f1 !important;
              color: #777 !important;

              svg {
                margin-left: 8px;
                path {
                  fill: $color-gray;
                }
              }
            }
          }
        }
      }
    }
  }

  ._title-with-timer {
    border-bottom: 1px solid $color-ice;

    ._med-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      ._title-box {
        padding-left: 0;
      }

      ._timer {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
          width: 18px;
          height: 18px;
          position: relative;
          top: -1px;

          path {
            fill: $color-blue;
          }
        }

        ._timer-clock {
          font-size: 16px;
          color: $color-blue;
          font-weight: $fw700;
          background-color: $color-white;
          width: 34px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  // Cols - Col
  ._columns {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include media-breakpoint-up(xs) {
      flex-direction: column;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    ._column {
      @include media-breakpoint-up(md) {
        height: 100vh;
      }

      @include media-breakpoint-up(xl) {
        height: calc(100vh - 15vh);
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {

        ._block {
          @include media-breakpoint-up(xs) {
            padding-bottom: 20px;
          }

          &:last-child {
            @include media-breakpoint-up(md) {
              border-bottom: 0;
            }
          }
        }
      }

      &:nth-child(1) {

        @include media-breakpoint-up(xs) {
          width: $percent-100;
        }

        @include media-breakpoint-up(lg) {
          width: 40%;
        }

        ._block {
          ._advertiser-card {
            padding-top: 0;
            
            @include media-breakpoint-up(xs) {
              padding-bottom: 0;
            }

            @include media-breakpoint-up(md) {
              padding-bottom: 20px;
            }
          }

          ._product-thumbnail.small {
            padding-right: 15px;
          }
        }
      }

      &:nth-child(2) {

        @include media-breakpoint-up(xs) {
          width: $percent-100;
        }

        @include media-breakpoint-up(md) {
          border: {
            left: 1px solid $color-ice;
            right: 1px solid $color-ice;
          }
          padding: 0 20px;
          margin-right: 20px;
        }

        @include media-breakpoint-up(lg) {
          width: 50%;
        }

        ._block {
          &:nth-child(1) {
            @include media-breakpoint-up(md) {
              padding-bottom: 20px;
            }
          }

          .select-box._expand {
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &:nth-child(3) {

        @include media-breakpoint-up(xs) {
          width: $percent-100;
        }

        @include media-breakpoint-up(lg) {
          width: 40%;
        }

        ._block {
          &:nth-child(1) {
            @include media-breakpoint-up(md) {
              border-bottom: 0;
            }
          }

          &:nth-child(2) {
              border-bottom: 0;
          }

          ._list-content {
            li {
              padding: 8px 0;
              position: relative;

              ._item-title {
                margin-bottom: 0;
              }
            }
          }

          .select-box {
            margin-bottom: 10px;

            label {
              ._cols {
                max-height: 60px;
              }
            }
          }
        }

        .checkout-actions {
          @include media-breakpoint-up(xs) {
            @include padding-lt-rt;
            padding-bottom: 20px;
          }

          @include media-breakpoint-up(md) {
            padding: 0;
          }
          button.btn {
            max-width: 450px;
          }
        }
      }
    }
  }
}

// Adjust Product Thumbnail

._product-thumbnail.small {
  padding: 10px 0;
  border-bottom: 1px dashed #f9f9f9;

  &:last-child {
    border-bottom: 0;
  }

  ._col {
    ._image {
      width: 40px;
      height: 40px;
    }

    ._product-info {
      .title {
        margin-bottom: 0;
        font-size: 12px;
      }

      ._product-price {
        margin-top: 0;

        .ui-item__discount-price {
          margin-right: 5px;
        }

        .ui-item__price {
          .price-tag-fraction {
            font-size: 16px;
          }
        }
      }
    }

    &:last-child {
      .trash-item {
        svg {
          width: auto;
          height: 16px;
        }
      }
    }
  }
}
