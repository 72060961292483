#my-sales {

	._wrap-title-and-tabs {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include media-breakpoint-up(xs) {
			flex-direction: column;
		}

		@include media-breakpoint-up(md) {
			flex-direction: row;
			margin-bottom: 1px;
		}

		._title-box {
			@include media-breakpoint-up(xs) {
				width: 100%;
			}

			@include media-breakpoint-up(md) {
				width: 30%;
			}
		}
		._material-tabs {
			@include media-breakpoint-up(xs) {
				width: $percent-100;
				border: {
					top: 1px solid $color-ice-bold;
					bottom: 1px solid $color-ice-bold;
				};
			}
			
			@include media-breakpoint-up(md) {
				border: 0;
				width: 70%;
			}
		}
		
		._title-box {
			margin-bottom: 0;
        }
        
        //=======================================
        // UI * Tabs Material UI
        //=======================================

        .MuiTabs-root._material-tabs {
            
            .MuiTabs-scroller {

                .MuiTabs-flexContainer {
                    
                    justify-content: space-between;
                    
                    button.MuiButtonBase-root.MuiTab-root {
                        width: auto;                
                    }                
                }
            }
        }
	}

	._accordion {
		
		._accordion-content {

			ul._list-content {

                li {

                    ._wrap-user {
                        display: flex;
                        align-items: center;

                        ._username {
                            font-size: 13px;
                            font-weight: $fw700;
                            color: $color-gray-bolder;
                            margin-left: 8px;
                        }
                    }
                }

				li.flex {

					._item-content {
						display: flex;
						align-items: flex-end;
						justify-content: flex-start;

						._frete {

							font-size: 12px;
							position: relative;
							top: -2px;
							margin-left: 6px;

							svg {

								width: 18px;
								height: auto;
								position: relative;
								top: -1px;
								margin-right: 4px;

								path {
									fill: $color-black;
									opacity: 0.7;
								}
							}
						}
					}
				}
			}
		}
	}

}

#_my-sales {
	
	._all-sales {
		
		._accordion.accordion {
            
            .card {
                
                .card-header {
                    
                    button.btn-link {

                        position: relative;
                        
                        ._product {

                            display: flex;
                            align-items: center;
                            justify-content: flex-start;

                            ._product-image {
                                width: 108px;
                                height: 108px;
                                overflow: hidden;
                                border: 1px solid $color-ice;
                                border-top: 0;
                                border-left: 0;

                                img {
                                    height: 100%;
                                    width: auto;
                                }
                            }


                            ._wrap-product-info {

                                margin-left: 15px;
                                margin-top: 10px;
                                text-align: left;
                                
                                .product-info-title {
                                    font-size: 14px;
                                    font-weight: $fw700;
                                    color: $color-black;
                                }

                                ._product-price {
                                    margin-bottom: 5px;
                                }

                                .tag-date {
                                    font-size: 11px;
                                    color: $color-gray;
                                    font-weight: $fw700;
                                    margin-bottom: 5px;

                                    .the-date {
                                        color: $color-black;
                                        font-size: 13px;
                                    }
                                }

                                ._delivery-address {
                                    margin-top: 15px;
                                    margin-bottom: 30px;

                                    .localtion-address {
                                        color: $color-gray;
                                        display: flex;
                                        align-items: flex-start;
                                        justify-content: flex-start;
                                        max-width: 280px;
                                        font-size: 12px;
                                        font-weight: $fw700;
                                        margin-top: 8px;

                                        .icon {

                                            margin-right: 10px;
                                            position: relative;
                                            top: 2px;

                                            svg {
                                                height: 15px;
                                                width: auto;

                                                path {
                                                    fill: $color-red;
                                                }
                                            }
                                        }
                                    }

                                }

                                ._seller {

                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: flex-start;

                                    ._seller-info {
                                        margin-left: 10px;
                                        font-weight: $fw700;
                                        font-size: 12px;

                                        ._seller-info-name {
                                            margin: 2px 0;
                                        }

                                        .open-messages {
                                            color: $color-blue-light;
                                        }

                                    }

                                }

                            }
                        }
                        
                        .payment-status {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            font-size: 10px;
                            font-weight: $fw700;
                            background-color: $color-ice;
                            color: $color-gray;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 2px 8px;
                            border: 1px solid $color-ice-bold;
                            border-right: 0;
                            border-bottom: 0;
                            border-top-left-radius: 5px;
                            text-transform: lowercase;
                            
                            svg {
                                height: 8px;
                                width: 8px;
                                margin-left: 4px;
                            }
                        }
                        
                        .payment-status.delivered {
                            svg {
                                path {
                                    fill: $color-green;
                                }
                            }
                        }
                        
                        .payment-status.canceled {
                            svg {
                                path {
                                    fill: $color-red;
                                }
                            }
                        }
                        
                        .payment-status.waiting {
                            svg {
                                path {
                                    fill: $color-orange;
                                }
                            }
                        }

                    }
                }
                
                .collapse {
                    
                    .card-body {
                        
                        padding: 10px;
                        
                        ._sales-info {
                            
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            min-height: 232px;
                            
                            @include media-breakpoint-up(xs) {
                                flex-direction: column-reverse;
                            }
                            
                            @include media-breakpoint-up(md) {
                                flex-direction: row;
                            }
                            
                            ._wrap-info,
                            ._wrap-seller {
                                width: 100%;
                                max-width: 360px;
                                background-color: #fbfbfb;
                                padding: 15px;
                            }
                            
                            ._wrap-info {
                                
                                
                                ._info {
                                    
                                    ._info-item {

                                        font-size: 13px;
                                        font-weight: $fw700;
                                        color: $color-gray-bolder;
                                        margin-bottom: 10px;

                                        &:last-child {
                                            margin-bottom: 0;
                                        }

                                        ._info-item-title {
                                            color: $color-gray;
                                            margin-bottom: 4px;
                                        }

                                        ._points {
                                            font-size: 16px;
                                        }
                                    }

                                }
                                
                                ._total {
                                    display: flex;
                                    align-items: flex-end;
                                    justify-content: space-between;
                                    width: 100%;
                                    border-top: 2px solid $color-ice-bold;
                                    margin-top: 10px;
                                    padding-top: 10px;
                                    
                                    ._total-text,
                                    ._total-points {
                                        font-weight: $fw700;
                                        font-size: 13px;
                                    }
                                    
                                    ._total-text {
                                        color: $color-gray;
                                        font-size: 15px;
                                    }
                                    
                                    ._total-points {
                                        color: $color-gray-bolder;
                                        font-size: 18px;
                                    }
                                }
                                
                            }
                            
                            ._wrap-seller {
                                
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: $color-white;
                                
                                @include media-breakpoint-up(xs) {
                                    margin-left: 0;
                                }
                                
                                @include media-breakpoint-up(md) {
                                    margin-left: 15px;
                                }
                                
                                ._seller {
                                    text-align: center;
                                    
                                    ._seller-image {
                                        .user-image {
                                            margin: 0 auto;
                                        }
                                    }

                                    ._seller-info {

                                        ._seller-info-title,
                                        ._seller-info-name {
                                            font-weight: $fw700;
                                        }
                                        
                                        ._seller-info-title {
                                            font-size: 12px;
                                            color: $color-gray;
                                        }
                                        
                                        ._seller-info-name {
                                            font-size: 14px;
                                            margin-bottom: 5px;
                                        }

                                    }
                                }
                            }
                            
                        }
                        
                    }
                    
                }
            }
		}
	}
	
}