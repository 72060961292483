#view_home{
    
    background-color: $color-white;
    
    .new-account-banner {
        
        @include media-breakpoint-up(sm) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
            z-index: 9999 !important;
            overflow: hidden;
            display: none /*flex*/;
            align-items: flex-start;
            justify-content: center;    
        }
        
        
        ._wrap-new-account-banner {
            
            position: relative;
            
            
            @include media-breakpoint-up(sm) {
                top: 150px;
            }
            
            @include media-breakpoint-up(lg) {
                top: 300px;
            }
            
            ._close-modal {
                position: absolute;
                right: 2px;
                top: 2px;
                width: auto;
                
                @include media-breakpoint-up(xs) {
                    display: none;
                }
                
                @include media-breakpoint-up(sm) {
                    display: block;
                }
                
                svg {
                    
                    width: 16px;
                    height: 16px;
                    
                    path {
                        opacity: 0.6;
                    }
                    
                }
                
                &:hover {
                   
                    svg {
                    
                        path {
                            opacity: 1;
                        }
                    
                    }
                    
                }
            }
            
            ._wrap-new-account-banner--inside {

                background-color: $color-white;
                text-align: center;
                border-radius: 3px;
                
                @include media-breakpoint-up(xs) {
                    padding: 15px;    
                }
                
                @include media-breakpoint-up(sm) {
                    padding: 60px;
                }
                
                img {

                    @include media-breakpoint-up(xs) {
                        width: 290px;
                    }

                    @include media-breakpoint-up(sm) {
                        width: auto;
                    }
                }

                a {
                    display: block;
                    max-width: 200px;
                    margin: 0 auto;
                    margin-top: 20px;
                }
            }
            
        }
        
    }
    
    #hero-slider {
        
        position: relative;
//        overflow: hidden;
        
        @include media-breakpoint-up(xs) {
            padding: {
                top: 0px;
                bottom: 15px;
            }
        }
        @include media-breakpoint-up(md) {
            padding: {
                top: 25px;
            }
        }
        
        @include media-breakpoint-up(sm) {
            @include padding-lt-rt;
            // padding: 0;
        }

        @include media-breakpoint-up(lg) {
            @include padding-lt-rt;
        }
        
        .slider-container {
            
            padding-bottom: 30px;
            @include push--auto;
            max-width: 1340px;

            @include media-breakpoint-up(lg) {
                // @include padding-lt-rt;
            }

            a.slider-item {

                picture {

                    source,
                    img {
                        width: 100%;
                    }

                }

            }
            
            .swiper-pagination {
                
                bottom: -30px;
                
            }
            
            &::after {
                display: block;
                content: '';
                background-image: url("#{$path-image}/after-mobile-banner.png");
                background-repeat: repeat-x;
                background-position: left;
                background-size: contain;
                height: 8px;
                width: 100%;
                margin: 0 auto;
                margin-top: -5px;

                @include media-breakpoint-up(xs) {
                    width: 86.89%;
                }

                @include media-breakpoint-up(sm) {
                    width: 90.20%;
                }

                @include media-breakpoint-up(md) {
                    width: 90.55%;
                }
            }

            .slick-dots {
                bottom: 0;
            }

        }
        
        .slide-action {
            text-align: center;
            margin-top: 10px;
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }
    
    ._med-container {
        
        ._wrap-top-categories {
            margin-top: 30px;
            
            @include media-breakpoint-up(xs) {
                display: none;
            };

            @include media-breakpoint-up(lg) {
                display: block;
            }

            .headline {
                margin-bottom: 0;
            }
        }

        ._wrap-recently-added {

            
            @include media-breakpoint-up(xs) {
                padding: 15px 0;
            };
            
            @include media-breakpoint-up(lg) {
                padding: 40px 0;
            }

            ._recently-added {
                margin-top: 15px;


            }

            .slick-slider._recently-added {
            
                .slick-arrow.slick-prev,
                .slick-arrow.slick-next {
                    background-color: rgba(180,180,180,0.6);
                }

                .slick-arrow.slick-prev {
                    margin-left: 0;
                }

                .slick-arrow.slick-next {
                    margin-right: 0;
                }
            
            }

        }
        
        ._wrap-official-stores {
            
            
            @include media-breakpoint-up(xs) {
                padding: 20px 0 40px 0;
            };
            
            @include media-breakpoint-up(lg) {
                padding: 0 0 40px 0;
            }
            
            .headline {
                margin-bottom: 20px;
            }

            .slick-slider._official-stores {
            
                .slick-arrow.slick-prev,
                .slick-arrow.slick-next {
                    background-color: rgba(180,180,180,0.6);
                }

                .slick-arrow.slick-prev {
                    margin-left: 0;
                }

                .slick-arrow.slick-next {
                    margin-right: 0;
                }
            
            }
            
            ._ui-card.official-stores-slide {
                
                max-width: 290px;
                width: 100%;
                height: 320px;
                border: 1px solid #f7f7f7;
                border-radius: 2px;
                position: relative;
                
                ._ui-item-wrapper {
                    
                    .official-stores-slide-background {
                        
                        height: 130px;
                        overflow: hidden;
                        border-top-left-radius: 2px;
                        border-top-right-radius: 2px;
                        
                        img {
                            display: inline-block;
                            width: auto;
                            height: 100%;
                            margin: 0 auto;
                        }
                        
                    }
                    
                    .official-stores-slide-logo {
                        
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-start;
                        margin-top: -40px;
                        @include padding-lt-rt;
                        
                        .logo {
                            max-width: 120px;
                            height: 80px;
                            width: 100%;
                            border: 1px solid #f1f1f1;
                            border-radius: 6px;
                            background-color: $color-white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            img {
                                height: auto;
                                width: 100px;
                            }
                        }
                        
                        .title-wrapper {
                            display: block;
                            margin-left: 15px;
                            position: relative;
                            top: -5px;
                        }
                        
                    }
                    
                    .official-stores-slide-items {
                        
                        margin-top: 30px;
                        @include padding-lt-rt;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        
                        a {
                            display: block;
                            border: 1px solid #f1f1f1;
                            width: 80px;
                            height: 54px;
                            position: relative;
                            overflow: hidden;
                            
                            img {
                                height: 100%;
                                width: auto;
                                margin: 0 auto;
                            }
                            
                            .points-tag {
                                
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-start;
                                background-color: $color-white;
                                font-size: 10px;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                padding: 1px 6px;
                                border: 0;
                                border-top: 1px solid #f1f1f1;
                                border-right: 1px solid #f1f1f1;
                                border-top-right-radius: 6px;
                                color: $color-black;
                                font-weight: $fw700;
                                
                                .points-tag-symbol {
                                    font-size: 7px;
                                    margin-left: 2px;
                                    position: relative;
                                    top: 1px;
                                }
                                
                            }
                        }
                        
                    }
                    
                    .official-stores-action {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        bottom: 15px;
                        left: 0;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;
                        max-width: 100px;
                        text-align: center;
                        color: $color-black;
                        font-size: 13px;
                        opacity: 0.8;
                        
                        svg {
                            width: 7px;
                            height: 13px;
                            margin-left: 5px;
                            position: relative;
                            top: -1px;
                        }
                    }
                    
                }
                
            }
            
        }
        
    }
    
}

#top-categories {
    
    display: flex;
    padding-top: 20px;
    position: relative;
    

    .headline {
        margin-bottom: 20px;
    }

    ._grid {
        
        ._grid-col {
            
            display: block;
            overflow: hidden;
            text-align: center;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            
            ._grid-col-head {
                text-align: left;
                position: absolute;
                top: 0;
                left: 0;

                padding: {
                    top: 20px;
                    left: 20px;
                }

                span {
                    text-transform: lowercase;
                }
            }
            
            img {
                margin: 0 auto;
            }
            
        }
        
        &:nth-child(1) {
            
            ._grid-col {
                
                ._grid-col-head {
                    
                    span {
                        font-size: 16px;
                    }
                    
                    h1 {
                        font-size: 30px;
                    }
                    
                }
                
                
                margin-right: 15px;
                
                @include media-breakpoint-up(lg) {
                    width: 424px;
                    height: 405px;  
                }
                
                @include media-breakpoint-up(xl) {
                    width: 450px;
                    height: 445px;  
                }
            }
            
        }
        
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
            ._grid-col {
                
                ._grid-col-head {
                    
                    span {
                        font-size: 14px;
                    }
                    
                    h1 {
                        font-size: 20px;
                    }
                    
                }
                
                @include media-breakpoint-up(lg) {
                    width: 270px;
                    height: 195px;
                }
                
                @include media-breakpoint-up(xl) {
                    width: 290px;
                    height: 215px;
                }
            }
        }
        
        &:nth-child(2),
        &:nth-child(3) {
            
            align-self: flex-start;
            
        }
        
        &:nth-child(3) {
            margin-left: 15px;
        }
        
        &:nth-child(4),
        &:nth-child(5) {
            
            align-self: flex-end;
            position: absolute;
            right: 0;
            
        }
        
        &:nth-child(4) {
            
            @include media-breakpoint-up(lg) {
                right: 285px;
            }
            
            @include media-breakpoint-up(xl) {
                right: 305px;
            }
        }

        // Image #1
        &:nth-child(1) {
            
            ._grid-col {
                
                img {
                    width: 52%;
                }

            }
            
        }

        // Image #2
        &:nth-child(2) {
            
            ._grid-col {
                align-items: flex-end;
                
                img {
                    width: 48%;
                }

            }
            
        }

        // Image #3
        &:nth-child(3) {
            
            ._grid-col {
                align-items: flex-end;
                
                img {
                    width: 28%;
                }

            }
            
        }

        // Image #4
        &:nth-child(4) {
            
            ._grid-col {
                align-items: flex-end;
                
                img {
                    width: 35%;
                    position: relative;
                    top: 50px;
                }

            }
            
        }

        // Image #5
        &:nth-child(5) {
            
            ._grid-col {
                align-items: center;
                
                img {
                    width: 100%;
                }

            }
            
        }
    }

}