#invite-friends {

    padding-bottom: 15px;

    ._title-box {
        font-size: 14px;
        font-weight: 700;
        color: #3d3d40;
        padding: 22px 15px 15px 15px;
    }

    ._list-info {

        border: 1px solid #f1f1f1;
        background-color: $color-white;
        
        @include media-breakpoint-up(xs) {
            margin: 15px;
            padding: 15px;
        }

        @include media-breakpoint-up(md) {
            margin: 0;
            padding: 15px 15px 0 15px;
        }

        li {
            &:first-child {
                border-top: 0;
              }

              &:last-child {
                  border-bottom: 0;
                  padding-bottom: 15px;
              }
        }
        
    }

}