//=======================================
// UI * Filter
//=======================================

._filter {
    position: relative;
    
    @include media-breakpoint-up(xs) {
        margin-top: 0;
    }
    
    @include media-breakpoint-up(lg) {
        margin-top: 30px;
    }

    &:first-child {
        margin-top: 0;
    }
    
    button._filter-button,
    button._apply-price  {
        background-color: $color-white;
        border: 0;
        font-weight: $fw700;
    }
    
    button._apply-price {
        
        @include media-breakpoint-up(xs) {
            display: none; //inline-block;
            color: #fff;
            border: 1px solid #14233e;
            padding: 0.532rem 0.999rem;
            border-radius: 4px;
            font-weight: 700;
            font-size: 13px;
            background-color: #14233e;
            width: $percent-100;

            svg {
                path {
                    fill: $color-white;
                }
            }
        }
        
        @include media-breakpoint-up(lg) {
            position: absolute;
            right: 0;
            top: 4px;
            background-color: $color-white;
            color: $color-blue;
            font-size: 11px;
            width: auto;
            border: 0;
            padding: 0;

            svg {
                path {
                    fill: $color-blue;
                }
            }
        }

        svg {
            width: 11px;
            height: auto;
            margin-left: 4px;
        }
    }
    
    button._filter-button {
        width: $percent-100;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $color-gray-bolder;
        font-size: 16px;
        font-family: $font-nunito;
        font-weight: $fw600;
        
        svg {
            width: 12px;
            height: auto;

            @include media-breakpoint-up(xs) {
                display: block;
            }

            @include media-breakpoint-up(lg) {
                display: none;
            }
            
            path {
                fill: $color-gray-bolder;
            }
        }
        
        @include media-breakpoint-up(xs) {
            padding: 14px 15px;
            border-bottom: 1px solid $color-ice;
        }

        @include media-breakpoint-up(lg) {
            padding: 0;
            border-bottom: 0;
            margin-bottom: 10px;
        }
    }

    ._filter-item {

        @include media-breakpoint-up(xs) {
            background-color: #F9F9F9;
            padding: 15px 15px;
            display: none;
        }

        @include media-breakpoint-up(lg) {
            background-color: #FFFFFF;
            padding: 0 0;
            display: block;
        }

        // Load more in modal
        ._loadmore-in-modal {
            border: 0;
            padding: 0;
            background-color: transparent;
            color: $color-blue-light;
            font-size: 13px;
            font-weight: $fw700;
        }

        //Filter price
        .form-group {

            ._min-max {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .icon {
                    margin: 0 6px;
                    svg {
                        width: 9px;
                        height: auto;
                        opacity: 0.7;

                        path {
                            fill: $color-black;
                        }
                    }
                }

                .input-group {

                    .form-control {
                        border-left: 1px solid #eaeaea;
                        height: calc(1.417em + 0.75rem + 9px);
                        font-size: 12px;
                        font-weight: $fw700;
                        
                        &:focus {
                            border-left: 1px solid #eaeaea !important;
                        }

                        &::placeholder {
                            color: #858589 !important;
                        }
                    }
                }
            }

        }

        ._filter-selected {
            border: 0;
            background-color: #F1F1F1;
            font-size: 11px;
            font-weight: $fw700;
            color: $color-blue;
            padding: 0.4873rem 0.600rem;
            border-radius: 4px;
            margin-right: 10px;
            margin-bottom: 10px;
            text-transform: lowercase;
            text-align: left;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;

            svg {
                width: 8px;
                height: auto;
                position: relative;
                top: 1px;
                margin-left: 4px;

                path {
                    fill: $color-red;
                }
            }
        }
        
        ._filter-label {

            display: block;
            cursor: pointer;
            margin: 0;
            margin-bottom: 5px;
            
            input[type=radio],
            input[type=checkbox] {
                display: none;
                visibility: hidden;
            }
            
            ._filter-title {
                font-size: 13px;
            }

            ._filter-title, ._filter-count {
                font-weight: $fw700;
                color: $color-gray-bolder;
            }

            ._filter-count {
                font-size: 11px;
                opacity: 0.7;
                display: inline-block;
                margin-left: 6px;
            }
        }
    }

    ._filter-item._filter-show {
        display: block;
    }
}

.__form._sidebar {
    
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include media-breakpoint-up(xs) {
        max-width: none;
        margin-bottom: 15px;
    }
    
    @include media-breakpoint-up(lg) {
        max-width: 220px;
        min-width: 220px;
        display: block;
        margin-bottom: 0;
    }

    ._form-filter-footer {

        @include media-breakpoint-up(xs) {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            display: block;
        }

        button {
            display: block;
            font-size: 12px;
        }
    }

._wrap-filter {

    @include media-breakpoint-up(xs) {
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-left: 0;
    }

    ._filter {

        
        
        ._filter-label {
            font-size: 13px;
            font-weight: $fw700;
            color: $color-gray-bolder;
            margin-bottom: 6px;
            @include media-breakpoint-up(xs) {
                display: none;
            }
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        ._filter-bar {
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
            margin-bottom: 6px;
            background-color: $color-white;
            padding: 0.438rem 0.466rem;
            border-radius: 4px;
            
            @include media-breakpoint-up(xs) {
                border: 1px solid #eaeaea;
                cursor: pointer;
                display: flex;
            }
            
            @include media-breakpoint-up(lg) {
                display: none;
                border: 0;
                cursor: default;
            }

            a {
                max-width: none;
                width: auto;
                padding: 0.169rem 0.075rem;
                padding-top: 0;
                padding-bottom: 0;
                
                svg {
                    height: 15px;
                    width: auto;

                    path {
                        fill: $color-gray;
                    }
                }
            }

            ._filter-title {
                font-weight: $fw700;
                color: $color-gray-bolder;
                
                @include media-breakpoint-up(xs) {
                    font-size: 11px;
                }
                
                @include media-breakpoint-up(lg) {
                    font-size: 13px;
                }
            }
        }

        ._filter-content {
            

            ._form-filter-content {
                margin-bottom: 15px;

                ._mui-slider {
                    margin-top: 40px;
                    padding-left: 15px;
                }

                ._filter-item {
        
                    label {
                        input { display: none; }
                    }
        
                    ._name {
                        color: $color-gray-bolder;
                        font-size: 13px;
                        cursor: pointer;
                    }
        
                }

                ._form-filter-content._type-default {
                border: 0;
                
                .form-group {
                    
                    ._wrap-min-max {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;

                        ._wrap-input-group {
                            
                            ._label {
                                font-size: 12px;
                                color: $color-gray;
                            }

                            padding-left: 10px;
                            
                            &:first-child {
                                padding-left: 0;
                            }

                            .input-group {

                                .input-group-prepend {
                                    .input-group-text {
                                        font-size: 12px;
                                        font-weight: $fw700;
                                        color: $color-gray-bolder;
                                    }
                                }

                                .form-control {
                                    &:focus,
                                    &:active {
                                        border-color: $color-border !important;
                                        border-left: 0 !important;
                                    }
                                }
                            }

                        }
                    }

                }
            }
        
            }

            ._form-filter-content._type-default {
                border: 0;
                
                @include media-breakpoint-up(xs) {
                    @include padding-lt-rt;
                }
                
                @include media-breakpoint-up(lg) {
                    padding: 0;
                }
                
                .form-group {
                    
                    ._wrap-min-max {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;

                        ._wrap-input-group {
                            width: $percent-100;

                            ._label {
                                font-size: 12px;
                                color: $color-gray;
                            }

                            padding-left: 10px;
                            
                            &:first-child {
                                padding-left: 0;
                            }

                            .input-group {

                                .input-group-prepend {
                                    .input-group-text {
                                        font-size: 12px;
                                        font-weight: $fw700;
                                        color: $color-gray-bolder;
                                    }
                                }

                                .form-control {
                                    &:focus,
                                    &:active {
                                        border-color: $color-border !important;
                                        border-left: 0 !important;
                                    }
                                }
                            }

                        }
                    }

                }
            }

            ._form-filter-footer {
                button {
                    font-size: 12px;
                }
            }
            
        }

    }

    ._filter._active {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-white;
        z-index: 9999;

        ._filter-bar {
            background-color: $color-white;
            width: $percent-100;
            height: 50px;
            border: 0;
            border-bottom: 1px solid $color-ice;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include padding-lt-rt;
            flex-direction: row;
            border-radius: 0;
            
            a {
                
                .arrow-left {
                    display: block;
                }

                .chevron-down {
                    display: none;
                }

            }
            

            ._filter-title {
                font-size: 12px;
            }
        }

        ._filter-content {

            display: block;

            ._form-filter-content {

                ._filter-item {
                    border-bottom: 1px solid $color-ice;
                    .form-check-label {
                        padding: 0.625rem 0.75rem;
                    }

                }

            }

            ._form-filter-footer {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                
                button {
                    font-size: 13px;

                }
            }

        }
    }

    ._filter._inactive {

        ._filter-bar {

            a {

                svg {
                    margin-left: 5px;
                }

                .arrow-left {
                    display: none;
                }

                .chevron-down {
                    
                    @include media-breakpoint-up(xs) {
                        display: block;
                    }

                    @include media-breakpoint-up(lg) {
                        display: none;
                    }

                }
            }

        }

        ._filter-content {

            @include media-breakpoint-up(xs) {
                display: none;
            }

            @include media-breakpoint-up(lg) {
                display: block;
            }

            ._form-filter-content._type-select {
                border: 1px solid $color-border;
                border-radius: 5px;

                ._filter-item {
                    border-bottom: 1px solid $color-border;

                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }

            ._form-filter-content {

                ._filter-item {

                    display: flex;
                    align-items: center;

                    .form-check-label {
                        position: relative;
                        width: 100%;
                        padding: 0.625rem 0.75rem;


                        .check {
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            border: 0;

                            svg {
                                display: none;
                            }

                        }

                        ._name {
                            position: relative;
                            z-index: 999;
                        }

                        .form-check-input:checked ~ .check {
                            background-color: $color-ice;
                        }

                        
                    }
                    &::after {
                        display: block;
                        content: '';
                        background-image: url('#{$path-image}/arrow-select-right.png');
                        background-repeat: no-repeat;
                        background-size: 8px;
                        height: 20px;
                        width: 20px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin-top: auto;
                        margin-bottom: auto;
                        opacity: 0.7;
                    }
                }

            }

            ._form-filter-footer {
                @include media-breakpoint-up(xs) {
                    display: flex;
                }
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

        }

    }
    
}
}

._form-order-by {

    ._apply-finter-button {

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

//=======================================
// UI * Filter - Modal Categories
//=======================================

.form.form-filter {

    .search {
        padding: 0 15px;
        max-width: 430px;
        width: $percent-100;
        margin: 0 auto;
        margin-top: 15px;
        margin-bottom: 30px;
    }

    ._wrap-items {
        overflow: hidden;
        overflow-y: scroll;
        height: 100%;
        background-color: $color-white;
        padding-bottom: 15px;

        @include media-breakpoint-up(xs) {
            max-height: none;
        }

        @include media-breakpoint-up(md) {
            max-height: 360px;
        }
    }

}

.form.form-filter._brands {
    margin-left: 15px !important;
}