//=======================================
// UI * ModalBase
//=======================================
.modal-dialog {

    .modal-content {

        .modal-header {

            border-bottom: 0;

            
        
        }
        .modal-body {

            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            ._title {
                font-size: 16px;
                color: $color-black;
                font-weight: $fw700;
                text-align: center;
                max-width: 420px;
                width: $percent-100;
                margin: 0 auto;
                margin-bottom: 30px;
                ._subtitle {
                    display: block;
                    font-size: 13px;
                    color: $color-gray-bolder;
                    font-weight: $fw700;
                    margin-top: 5px;
                }

            }

        }

    }

}

//=======================================
// UI * ModalProposal
//=======================================
._form._form-proposal {
    
    max-width: 420px;
    width: $percent-100;
    margin: 0 auto;
    padding-bottom: 15px;
    @include padding-lt-rt;

    ._product {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        border: 1px solid $color-ice;
        margin-bottom: 15px;
        padding: {
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 10px;
        };

        ._product-image {
            height: 40px;
            width: 40px;
            border-radius: 4px;
            border: 1px solid $color-ice;
            overflow: hidden;

            img {
                width: auto;
                height: $percent-100;
            }
        }

        ._product-info {
            margin-left: 10px;
            ._store-title {
                font-size: 11px;
                font-weight: $fw700;
                margin-bottom: 2px;
            }
            
            ._product-title {
                font-size: 13px;
                font-weight: $fw700;
            }

            ._product-price {
                margin-top: 0;
            }

        }
    }

    .form-group {
        border: 1px solid $color-border;
        padding: 5px 10px 10px 10px;
        padding-bottom: 0;
        border-radius: 4px;

        label {
            font-size: 13px;
            color: $color-gray;
            font-weight: $fw700;
            font-family: $font-comfortaa;
        }

        .form-control {
            padding-top: 0;
            padding-bottom: 5px;
            padding-left: 0;
            border: 0;
            height: calc(0.962em + 0.75rem + 0px);
            font-size: 16px;
            color: $color-black;
            font-family: $font-comfortaa;

            &:focus,
            &:active {
                border: 0 !important;
            }

            &::placeholder {
                font-size: 11px;
                opacity: 0.7;
            }
        }

        span._error {
            color: $color-red;
            font-size: 12px;
        }

        textarea.form-control {
            max-height: 100px;
            height: 100vw;
            resize: none;
            margin-top: 4px;
            font-size: 13px;
        }
    }

    ._form-footer {
        text-align: center;
        button {
            max-width: none;
            width: $percent-100;
        }
    }

}

//=======================================
// UI * ModalTaxs
//=======================================
._title.modal-taxs {
    margin-bottom: 0 !important;
}

._taxs-list {
                        
    li {
        
        border-bottom: 1px solid $color-ice;
        padding: 10px 15px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        &:nth-child(1) {
            ._taxs-list-title {
                color: $color-black;
                font-weight: $fw700;

                @include media-breakpoint-up(xs) {
                    font-size: 12px;
                }

                @include media-breakpoint-up(md) {
                    font-size: 14px;
                }
            }
            
            ._taxs-list-value {
                ._title {
                    font-size: 12px !important;
                    color: $color-black;
                    font-weight: $fw700;
                }
            }
        }
        
        &:last-child {
            border-bottom: 0;
            padding-bottom: 15px;
        }
        
        ._taxs-list-title {
            font-weight: $fw700;
            color: $color-gray-bolder;
            margin-bottom: 2px;

            @include media-breakpoint-up(xs) {
                font-size: 12px;
            }

            @include media-breakpoint-up(md) {
                font-size: 13px;
            }
        }
        
        ._taxs-list-value {
            ._title {
                color: $color-gray;
                text-transform: capitalize;
                font-size: 13px;
                font-weight: $fw600;
            }
            
            ._value {
                color: $color-gray-bolder;
                font-weight: $fw700;
                
                @include media-breakpoint-up(xs) {
                    font-size: 12px;
                }

                @include media-breakpoint-up(md) {
                    font-size: 14px;
                }
            }
            
            ._value._price {
                font-size: 18px;
            }
        }
    }
    
    li._wbg {
        background-color: $color-ice-light;
        border-bottom: 2px solid $color-ice;
    }

}

//=======================================
// UI * Flull Width
//=======================================

.full-width {

    @include media-breakpoint-up(xs) {
        padding-right: 0;
    }

    @include media-breakpoint-up(md) {
        padding-right: 15px;
    }

    div.modal-dialog {
        
        @include media-breakpoint-up(xs) {
            margin: 0 !important;
            height: $percent-100 !important;
        }

        @include media-breakpoint-up(md) {
            margin: 1.75rem auto !important;
            height: auto !important;
        }
        
        div.modal-content {

            @include media-breakpoint-up(xs) {
            border: 0 !important;
            border-radius: 0 !important;
            height: $percent-100 !important;
            }

            @include media-breakpoint-up(md) {
                border: 1px solid rgba(0, 0, 0, 0.2) !important;
                border-radius: 0.3rem !important;
            }

            div.modal-header {
                
                @include media-breakpoint-up(xs) {
                    padding: 0 !important;
                }
                
                @include media-breakpoint-up(md) {
                    padding: 1rem 1rem !important;
                    padding-bottom: 0 !important;
                }

                .close {
                    @include media-breakpoint-up(xs) {
                        display: none !important;
                    }
                    
                    @include media-breakpoint-up(md) {
                        display: block !important;
                    }
                }

                ._view-head {
                    overflow: hidden;
                    width: $percent-100;
                    
                    @include media-breakpoint-up(xs) {
                        padding-bottom: 10px;
                    }   
                    @include media-breakpoint-up(md) {
                        padding-bottom: 0;
                    }
                
                    ._view-head-content {
                      
                      background-color: $color-white;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      position: relative;
                      
                      @include media-breakpoint-up(xs) {
                          height: 50px;
                          -webkit-box-shadow: 0 8px 8px 4px rgba(120, 120, 120, 0.1);
                          box-shadow: 0 1px 8px 4px rgba(120, 120, 120, 0.1);
                        }   
                        @include media-breakpoint-up(md) {
                            height: auto;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                        }
                
                      ._back-history {
                        height: 50px;
                        width: 38px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: $color-white;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @include media-breakpoint-up(xs) {
                            height: 50px;
                        }   
                        @include media-breakpoint-up(md) {
                            height: 40px;
                        }
                
                        svg {
                          width: 8px;
                          height: auto;
                
                          path {
                            fill: $color-gray;
                          }
                        }
                      }

                      ._back-history {
                        @include media-breakpoint-up(xs) {
                            display: flex;
                        }
                        @include media-breakpoint-up(md) {
                            display: none;
                        }
                      }
                                
                      .modal-title {
                          font-weight: $fw700;
                          color: $color-gray-bolder;
                          
                          @include media-breakpoint-up(xs) {
                            font-size: 13px;
                        }   
                        @include media-breakpoint-up(md) {
                            font-size: 18px;
                            margin-top: 40px;
                            margin-right: -40px;
                        }
                      }
                    }
                  }
            }

            div.modal-body {
                padding-left: 15px;
                padding-right: 15px;

                @include media-breakpoint-up(xs) {
                    padding-top: 1rem;
                }   
                @include media-breakpoint-up(md) {
                    padding-top: 0;
                }

                
                form {
                    ._content-title {
                        font-size: 13px;
                        color: $color-gray-bolder;
                        line-height: 24px;
                        max-width: 320px;
                        width: $percent-100;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        text-align: center;
                        margin-top: 5px;
                        
                        @include media-breakpoint-up(xs) {
                            font-weight: $fw700;
                        }
                        
                        @include media-breakpoint-up(md) {
                            font-weight: $fw400;
                        }
                      }

                    height: auto; //$percent-100;

                    section._form-body {
                        height: calc(100% - 125px);
                    }
                    
                    section._form-body._credit-card-form {
                        height: calc(100% - 160px);
                    }

                    .btn.btn-submit-modal {
                        margin-top: 20px;
                        
                        @include media-breakpoint-up(xs) {
                            padding: 0.719rem 0.75rem;
                        }
                        
                        @include media-breakpoint-up(md) {
                            padding: 0.469rem 0.75rem;
                        }
                    }

                }
            }
        }
    }
}

._form._change-addrress {
    max-width: 320px;
    margin: 0 auto;
    padding: 15px;

}

//=======================================
// UI * Material ui Modal Diloag
//=======================================

.MuiDialogTitle-root.dialog-title {
    padding: 0 !important;

    h2.MuiTypography-root.MuiTypography-h6 {
        @include media-breakpoint-up(xs) {
            margin-bottom: 15px;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 0px;
        }

        ._view-head-content {
            width: $percent-100;
            display: flex;
            align-items: center;
            justify-content: center;
            @include padding-lt-rt;
            position: relative;
            height: 50px;
            
            @include media-breakpoint-up(xs) {
                box-shadow: 0 1px 8px 4px rgba(120, 120, 120, 0.1);
            }
    
            @include media-breakpoint-up(md) {
                box-shadow: none;
            }

            .btn {
                max-width: none;
                width: auto;
                position: absolute;
                top: 0;
                bottom: 0;
                
                @include media-breakpoint-up(xs) {
                    display: block;
                    left: 15px;
                }
                
                @include media-breakpoint-up(md) {
                    left: auto;
                    right: 15px;
                }

                svg {
                    height: auto;
                    
                    @include media-breakpoint-up(xs) {
                        width: 8px;
                    }
                    
                    @include media-breakpoint-up(md) {
                        width: 10px;
                    }
                }
            }

            ._title-view {
                font-family: $font-comfortaa;
                font-weight: $fw700;
                color: $color-gray-bolder;
                
                @include media-breakpoint-up(xs) {
                    font-size: 13.3px;
                }
                
                @include media-breakpoint-up(md) {
                    font-size: 18px;
                    margin-top: 30px;
                }
            }
        }
    }
}

//=======================================
// UI * Material ui Modal Pass Recovery
//=======================================

.MuiDialogContent-root._recovery-password {

    .MuiTypography-root {

        p._sub-title {
            font-family: $font-comfortaa;
            font-weight: $fw700;
            color: $color-gray-bolder;
            text-align: center;
            margin: 5px 0 15px 0;
        }

    }

}


//=======================================
// UI * Modal ( Product Proposal)
//=======================================
._product {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: {
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 5px;
    };

    ._product-image {
        height: 40px;
        width: 40px;
        border-radius: 4px;
        border: 1px solid $color-ice;
        overflow: hidden;

        img {
            width: auto;
            height: $percent-100;
        }
    }

    ._product-info {
        margin-left: 10px;
        ._store-title,
        ._product-title,
        ._product-price {
            font-family: $font-comfortaa;
          color: $color-gray-bolder;
          font-weight: $fw700;
        }

        ._store-title {
            font-size: 11px;
            margin-bottom: 2px;
          }
          
          ._product-title {
            font-size: 13px;
          }
          
          ._product-price {
            margin-top: 0;
        }

    }
}

.manifest {

    .image-gallery {

        .image-gallery-content {

            .image-gallery-slide-wrapper {

                .image-gallery-icon.image-gallery-left-nav,
                .image-gallery-icon.image-gallery-right-nav {
                    svg {
                        width: 44px;
                        height: auto;
                    }

                    &:hover {
                        color: $color-red;
                    }
                }

                .image-gallery-bullets {
                    display: flex !important;
                    bottom: 10px;

                    .image-gallery-bullets-container {
                        margin: 0 auto;
                    }
                }

            }

        }

    }

}

._title.manifest {
    margin-bottom: 0 !important;

    @include media-breakpoint-up(xs) {
        font-size: 18px !important;
    }
    
    @include media-breakpoint-up(md) {
        font-size: 28px !important;
    }
}

.betta-modal {
    margin: 0 !important;

    ._subtitle {
        display: none !important;
    }
    
}

//=======================================
// UI * Custom Width Bootstrap Modal
//=======================================

.modal-size-45w {
    max-width: none!important;
    
    @include media-breakpoint-up(xs) {
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        width: 60%;
    }
}


//=======================================
// UI * Modal Filter
//=======================================

._modal-filter.modal-body {
    margin: 0;
    padding: 0 !important;
    // max-height: 100vw;
    
    @include media-breakpoint-up(xs) {
        overflow: hidden;
        overflow-y: scroll;
    }
    
    @include media-breakpoint-up(lg) {
        overflow-y: hidden;
    }

    form {
        max-width: none !important;
        padding: 0 !important;
        max-height: 442px;
        // overflow: hidden;
        // overflow-y: scroll;

        ._filter-item {
            label._filter-label {

                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #F1F1F1;
                padding: 0.469rem 0.9375rem;
                width: $percent-100;
                cursor: pointer;

                input[type=radio],
                input[type=checkbox] {
                    display: none;
                    visibility: hidden;
                }

                ._filter-title {
                    color: $color-gray-bolder;
                    font-weight: $fw700;
                    font-size: 14px;
                }

                svg {

                    width: 7px;
                    height: auto;

                    path {
                        fill: $color-gray;
                        opacity: 0.8;
                    }
                }
            }

            &:last-child {

                label._filter-label {
                    border-bottom: 0;
                }

            }
        }
    }

}


//=======================================
// UI * My Purchases & My Sales
//    * Product Actions Material UI
//    * Modal
//=======================================

._modal-action-product {

	.subtitle {
		font-size: 13px;
		font-weight: $fw700;
		color: $color-gray-bolder;
		font-family: $font-comfortaa;
		text-align: center;
		
		@include media-breakpoint-up(xs) {
			margin-top: 10px;
			margin-bottom: 20px;
		}
		
		@include media-breakpoint-up(md) {
			margin-top: 5px;
			margin-bottom: 25px;
		}
	}

	.MuiTypography-root {

		._form-modal-product {

			._check-box {
				margin-bottom: 10px !important;
			}

			.btn-submit {
				font-family: $font-comfortaa;
				font-weight: $fw700;
				color: $color-white;
				margin: auto;
				display: block;
				margin-top: 20px;
				
				svg {
					position: relative;
					// top: 2px;
					margin-left: 10px;

					path {

						fill: $color-white;
					}
				}
				
				&:disabled {
					color: #777777;
					
					svg {

						path {

							fill: #777777 !important
						}
					}
				}
			}

		}

		.btn-submit {
			font-family: $font-comfortaa;
			font-weight: $fw700;
			color: $color-white;
			margin: auto;
			display: block;
            margin-top: 20px;
            
			svg {
				position: relative;
				// top: 2px;
				margin-left: 10px;

				path {

					fill: $color-white;
				}
			}
			
			&:disabled {
				color: #777777;
				
				svg {

					path {

						fill: #777777 !important
					}
				}
			}
		}
	}
}


//=======================================
// UI * Add Shopping Cart Modal
//=======================================
.shopping-cart-dialog {
    padding: 0 !important;

    @include media-breakpoint-up(xs) {
        margin-top: -15px;
    }

    @include media-breakpoint-up(md) {
        margin-top: 20px;
    }

    .MuiTypography-root {
        
        ._advertiser-card {
            padding-left: 15px;
            padding-right: 15px;
            border-bottom: 1px solid #F1F1F1;
            
            @include media-breakpoint-up(md) {
                border-top: 1px solid #F1F1F1;
            }
        }

        ._product-thumbnail {
            padding: 15px;
            border-bottom: 1px solid #F1F1F1;
        }
    }
}

//=======================================
// UI * Material UI Dialog Footer
//=======================================

.MuiDialogActions-root.dialog-actions.MuiDialogActions-spacing {

    flex-direction: column;
    
    @include media-breakpoint-up(md) {
        padding: 15px 0;
    }

    button {
        
        font-weight: $fw700;

        &:not(:first-child) {
            margin-left: 0;
            margin-top: 8px;
        }
    }
    
    button.accept {

        svg {
            margin-left: 8px;
            path {
                fill: $color-white
            }
        }
    }

    button.cancel {
        background-color: #f1f1f1 !important;
        color: #777 !important;

        svg {
            margin-left: 8px;
            path {
                fill: $color-gray
            }
        }
    }

}