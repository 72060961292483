#my-profile {
  ._title-box {
    margin-bottom: 10px;
  }

  ._add-payment-method {
	background-color: $color-white;
	  ._wrapper {
		  text-align: left;
	  }
  }
}

._wrap-form-header {
  background-color: $color-ice;
  padding-left: 15px;
  padding-right: 0;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color-ice-bold;

  ._title-box {
    font-weight: $fw700;
    padding: 0 !important;
    opacity: 0.8;
    background-color: $color-ice;
    margin-bottom: 0 !important;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      max-width: none;
      width: auto;
      height: 50px;
      padding: 0 0.650rem;
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-gray-bolder;
      font-weight: $fw700;

      svg {
        width: 22px;
        height: 22px;

        path {
          fill: $color-gray;
        }
      }
    }

    button._confirm {
      svg {
        path {
          fill: $color-green;
        }
      }
    }
  }
}
