//=======================================
// UI * Accordion
//=======================================

._accordion {

    
    ._accordion-head {
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-white;
        border-bottom: 1px solid $color-ice;
        padding: 20px 15px;
        cursor: pointer;
        position: relative;

        ._visible-content {
            color: $color-black;
            font-weight: $fw700;
            width: $percent-100;
        }

        ._chevron-icon {

            svg {
                width: 14px;
                height: auto;

                path {
                    fill: $color-black;
                    opacity: 0.5;
                }
            }
        }

        ._tag {
            font-size: 10px;
            font-weight: $fw700;
            color: rgba(61, 61, 64, 0.8);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: $color-light;
            border-top: 1px solid $color-ice;
            border-left: 1px solid $color-ice;
            padding: .200rem .500rem;
            border-top-left-radius: 12px;
            position: absolute;
            right: 0;
            bottom: 0;

            svg {
                width: 8px;
                height: 8px;
                margin-right: 4px;
                position: relative;
                top: 0px;

                path {
                    
                    fill: $color-ice-bold;

                }
            }
        }

        ._tag.delivered {
            svg {

                path {
                    
                    fill: $color-green;

                }
            }
        }

        ._tag.canceled {
            svg {

                path {
                    
                    fill: $color-red;

                }
            }
        }

        ._tag.returned {
            svg {

                path {
                    
                    fill: $color-nude;

                }
            }
        }
    }

}

._accordion._inside {

    
    ._accordion-head {
        padding: 14px 15px;

        ._visible-content {
            font-size: 12px;
        }

        ._chevron-icon {

            svg {
                width: 11px;
            }
        }
    }
    
    ._accordion-content._inside {
        padding: 12px 15px;

        li {
            list-style: none;
            padding: 0;
            margin-bottom: 15px;
        }
    }

}

//=======================================
// UI * Product Thumbnail
//=======================================

._product-thumbnail {

    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    ._col {

        display: flex;
        align-items: center;
        
        &:first-child {
            justify-content: flex-start;
            width: $percent-100;
        }
        
        &:last-child {
            justify-content: flex-end;
            width: $percent-50;

            button.trash-item {
                padding: 0 !important;
                max-width: none;
                width: auto;
                background-color: transparent;
                
                &:focus,
                &:active {
                    background-color: transparent !important;
                }

                svg {
                    width: 15px;
                    height: auto;

                    path {
                        fill: $color-gray;
                        opacity: 0.6;
                    }
                }
            } 
        }

        ._image {
            height: 80px;
            width: 80px;
            border-radius: 4px;
            overflow: hidden;

            img {
                width: $percent-100;
                height: auto;
            }
        }

        ._product-info {

            margin-left: 10px;

            .title {
                font-weight: $fw700;
                color: $color-black;
                font-size: 13px;
                margin-bottom: 5px;
            }
            
            .date {
                font-weight: $fw700;
                color: $color-black;
                font-size: 12px;
                
                .title {
                    font-size: 10px;
                    opacity: 0.9;
                    margin-bottom: 2px;
                }
            }
        }

        ul._list-content {

            li.flex {

                padding: 0;
                border-bottom: 0;

                ._item-content {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-start;

                    ._frete {

                        font-size: 12px;
                        position: relative;
                        top: -2px;

                        svg {

                            width: 18px;
                            height: auto;
                            position: relative;
                            top: -1px;
                            margin-right: 4px;

                            path {
                                fill: $color-black;
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }
        }
    }

}

//=======================================
// UI * List Content
//=======================================

._accordion-content._inside,
._list-content {

    li {
        padding: 12px 15px;
        border-bottom: 1px dashed $color-ice;


        ._item-title,
        ._item-content {
            font-weight: $fw700;
            color: $color-black;
        }
        ._item-title {
            font-size: 11px;
            opacity: 0.9;
            margin-bottom: 5px;

            button.button-info {
                background-color: transparent;
                max-width: inherit;
                width: auto;
                padding: 0.15rem;
                
                &:focus,
                &:active {
                    background-color: transparent !important;
                }

                svg {
                    width: 13px;
                    height: auto;

                    path {
                        fill: $color-gray;
                    }
                }
            }
        }

        ._item-content {
            font-size: 13px;
        }
    }
}

//=======================================
// UI * Timeline Order
//=======================================

._order-timeline {

    ._timeline-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 30px;

        ._col {

            .icon {
                height: 40px;
                width: 40px;
                min-width: 40px;
                max-width: 40px;
                border-radius: 100px;
                background-color: #F0F0F0;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 1;

                svg {
                    height: 18px;
                    width: auto;

                    path {
                        fill: $color-black;
                        opacity: 0.5;
                    }
                }

                &::before {
                    content: '';
                    display: block;
                    height: 30px;
                    border-left: 1px dashed #CCCCCC;
                    background-color: transparent;
                    position: absolute;
                    left: 19px;
                    top: 30px;
                }

            }

            .date,
            .hour {
                font-weight: $fw700;
                color: $color-black;
            }

            .date {
                font-size: 13px;
            }

            .hour {
                font-size: 12px;
                opacity: 0.8;
            }

            // First Col
            &:first-child {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;

                .title {
                    font-weight: $fw700;
                    color: $color-black;
                    font-size: 12px;
                    margin-left: 12px;
                }

                .icon {
                    &::before {
                        content: '';
                        // display: none;
                    }
                }

            }
        }

        // Timeline item
        &:first-child {
            padding-bottom: 0 !important;

            ._col {

                .icon {

                    background-color: $color-blue;

                    svg {
                        path  {
                            fill: $color-white;
                            opacity: 1;
                        }
                    }

                    &::before {
                        display: none;
                    }
                }

                .title {
                    color: $color-blue;
                }
            }
        }

        &:nth-child(2) {

            padding-top: 30px;

            ._col {

                .icon {
                    
                    &::after {
                        content: '';
                        display: block;
                        height: 30px;
                        border-left: 1px dashed #CCCCCC;
                        background-color: transparent;
                        position: absolute;
                        left: 19px;
                        top: -30px;
                    }
                }
            }
        }

        &:last-child {
            padding-bottom: 0 !important;

            ._col {

                .icon {
                    
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

}

//=======================================
// UI * Price in points
//=======================================

._price {

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .ui-item__price {
        display: flex;
        align-items: flex-start;
        
        .price-tag-fraction,
        .price-tag-symbol {
            font-weight: $fw700;
            color: $color-black;
        }

        .price-tag-fraction {
            font-size: 16px;
        }

        .price-tag-symbol {
            font-size: 10px;
            position: relative;
        }
    }

}

// Type real
._price.real {

    .ui-item__price {
        flex-direction: row-reverse;

        .price-tag-symbol {
            text-transform: uppercase;
            top: .185rem;
            margin-right: .135rem;
        }
    }

}

// Type points
._price.points {

    .ui-item__price {
        flex-direction: row;

        .price-tag-symbol {
            margin-left: .135rem;
        }
    }

}

// Size 2x
._price.real.medium,
._price.points.medium {

    .ui-item__price {

        .price-tag-fraction {
            font-size: 23px;
        }

        .price-tag-symbol {
            font-size: 11px;
        }
    }
}

// Size 2x real
._price.real.medium {

    .ui-item__price {

        .price-tag-symbol {
            top: .305rem;
        }
    }

}

// Size 2x points
._price.points.medium {

    .ui-item__price {

        .price-tag-symbol {
            top: .205rem;
        }
    }

}

//====================================
// UI * Checkbox large
//====================================

._check-box {

    max-width: 380px;
    width: $percent-100;

    ._check-box--label {
        margin: 0;
        background-color: #F9F9F9;
        width: $percent-100;
        padding: .900rem .750rem;
        border-radius: 4px;
        cursor: pointer;

        input[type=radio],
        input[type=checkbox] {
            display: none;
            visibility: hidden;
        }

        input:checked ~ ._cols > ._col > .icon.mark {
            background-color: $color-blue;

            svg {

                path {
                    fill: $color-white;
                }
            }
        }

        ._cols {

            display: flex;
            align-items: center;
            justify-content: space-between;

            ._col {

                
                &:first-child {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon {
                        height: 20px;
                        width: 20px;
                        min-width: 20px;
                        border-radius: 100px;
                        background-color: $color-ice-bold;
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        svg {
    
                            width: 10px;
                            height: auto;
    
                            path {
                                fill: #CCCCCC;
                            }
                        }
                    }

                    .title {
                        font-family: $font-comfortaa;
                        font-weight: $fw700;
                        color: $color-gray;
                        font-size: 12px;
                        max-width: 290px;
                        width: $percent-100;
                        margin-left: 10px;
                    }
                }

                &:last-child {

                    .icon {

                        svg {

                            height: 14px;
                            width: auto;

                            path {

                                fill: #909093;
                            }
                        }
                    }
                }

            }
        }
    }
}


//====================================
// UI * Status Message
//====================================

._status-message {
    font-size: 12px;
    font-weight: $fw700;
    font-family: $font-comfortaa;
    padding: .550rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 380px;
    width: $percent-100;

    .icon {
        margin-right: 10px;

        svg {

            width: 20px;
            height: auto;
            position: relative;
            top: 1px;

            path {
                fill: $color-red;
            }
        }
    }
}

._status-message.error {
    color: $color-red;
    background-color: rgba(194, 64, 63, 0.1);
    border-left: 4px solid rgba(194, 64, 63, 1);

    .icon {

        svg {

            path {
                fill: $color-red;
            }
        }
    }
}

._status-message.success {
    color: $color-white;
    background-color: $color-green;
    border-left: 4px solid $color-green;

    .icon {

        svg {

            path {
                fill: $color-white;
            }
        }
    }
}

//====================================
// UI * Green List
//====================================

.green-list {

    max-width: 380px;
    width: $percent-100;
    margin: auto;

    li {
        margin: 0;
        background-color: $color-white;
        width: $percent-100;
        padding: .900rem .750rem;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 0;

        &:last-child {
            margin-bottom: 0;
        }

        .icon {
            height: 20px;
            width: 20px;
            min-width: 20px;
            border-radius: 100px;
            background-color: $color-green;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {

                width: 10px;
                height: auto;

                path {
                    fill: $color-white;
                }
            }
        }

        .text {
            font-weight: $fw700;
            color: $color-gray;
            font-size: 12px;
            max-width: 290px;
            width: $percent-100;
            margin-left: 10px;
            font-family: $font-comfortaa; 
        }
    }

}

//====================================
// UI * Adversiter Card
//====================================

._advertiser-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    
    ._col {
        display: flex;
        align-items: center;
        
        &:first-child {
            width: $percent-100;
            justify-content: flex-start;
            padding-right: 10px;
        }

        &:last-child {
            width: 54%;
            justify-content: flex-end;
        }

        ._advertiser-card-logo {
        display: block;
        min-width: 50px;
        max-width: 50px;
        width: 50px;
        height: 50px;
        display: block;
        min-width: 50px;
        max-width: 50px;
        border-radius: 100px;
        overflow: hidden;
        background-color: $color-ice;
        position: relative;

        img {
            height: auto;
            width: 100%;
        }
        }

        ._advertiser-card-title--location {
        font-size: 14px;
        color: $color-black;
        font-weight: $fw700;
        margin-left: 10px;

        .title-location {
            display: block;
            font-size: 11px;
        }
        }

        ._advertiser-card-follow {
        @include media-breakpoint-up(xs) {
            margin-left: 10px;
        }

        @include media-breakpoint-up(sm) {
            margin-left: 88px;
        }

        .btn-follow {
            background-color: $color-blue;
            color: $color-white;
            font-size: 10px;
            padding: 0.219rem 0.889rem;
        }
        }

        ._advertiser-card-redirect {
        margin-left: 20px;

        button {
            padding: 0.25rem;

            svg {
            position: relative;
            top: 2px;
            height: 16px;
            width: auto;
            opacity: 0.6;
            }
        }
        }

    }
}

//====================================
// UI * Select Box - CC - Freight
//====================================

.select-box {
    border: 1px solid $color-ice;
    
    label {
        
        width: $percent-100;
        margin: 0;
        cursor: pointer;

        ._cols {

            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: {
                top: 10px;
                bottom: 10px;
                right: 10px;
                left: 0;
            };

            
            ._col {
                &:first-child {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                .icon {
                    padding: .500rem;
                    width: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        height: 18px;
                        width: auto;

                        path {
                            fill: $color-blue;
                        }
                    }

                    img {
                        height: auto;
                        width: 26px;
                    }
                }

                .text {
                    margin-left: 10px;

                    ._list-content {
                        li {
                            padding: 0;
                            border: 0;

                            ._item-title {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                input[type=radio],
                input[type=checkbox] {
                    display: none;
                }

                .check {
                    height: 18px;
                    width: 18px;
                    min-width: 18px;
                    background-color: $color-white;
                    border-radius: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid $color-ice-bold;

                    svg {
                        height: 10px;
                        width: auto;
                        opacity: 0;

                        path {
                            fill: $color-white;
                        }
                    }
                }

                input:checked ~ .check {
                    background-color: $color-blue;
                    border: 1px solid $color-blue;
                    
                    svg {
                        opacity: 1;
                    }
                }
            }
        }

        .content {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.select-box._expand {

    label {
        
        ._cols {
            ._col {
                
                .icon {
                    
                    position: relative;

                    .check {
                        position: absolute;
                        top: 0;
                        left: 28px;
                        height: 18px;
                        width: 18px;
                        min-width: 18px;
                        background-color: $color-white;
                        border-radius: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid $color-ice-bold;
    
                        svg {
                            height: 10px;
                            width: auto;
                            opacity: 0;
    
                            path {
                                fill: $color-white;
                            }
                        }
                    }
    
                    input:checked ~ .check {
                        background-color: $color-blue;
                        border: 1px solid $color-blue;
                        
                        svg {
                            opacity: 1;
                        }
                    }
                }

            }

        }
        
    }

}

//====================================
// UI * Box Information - Info - Addrs
//====================================

.box-information {

    ._set-user-location {
        max-width: inherit;
        margin-bottom: 10px;

        ._wrap-location {

            ._wrap-current-location {

                ._location {
                    font-weight: $fw700;
                }
            }
        }
    }
}