#view_buy-points {
    
    @include media-breakpoint-up(lg) {
        margin: 40px 0;
    }
    
    ._med-container {
        
        @include media-breakpoint-up(xs) {
            padding: 0;
        }

        @include media-breakpoint-up(lg) {
            @include padding-lt-rt;
        }
        
        ._cols {
            
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            
            @include media-breakpoint-up(xs) {
                flex-direction: column;
            }
            
            @include media-breakpoint-up(lg) {
                flex-direction: row;
            }
            
            ._col._col-left,
            ._col._col-right {
                .box {
                    background-color: $color-white;
                    padding: 20px 15px 20px 15px;
                    ._box-title {
                        font-weight: $fw700;
                        color: $color-black;
                        margin-bottom: 20px;
                        position: relative;

                        button { position: relative;}
                        
                        @include media-breakpoint-up(xs) {
                            font-size: 14px;
                        }

                        @include media-breakpoint-up(lg) {
                            font-size: 18px;
                        }
                    }
                }    
            }
            
            ._col._col-left {
                
                @include media-breakpoint-up(xs) {
                    width: 100%;
                }

                @include media-breakpoint-up(lg) {
                    max-width: 710px;
                    min-width: 610px;
                }
                
                .box._form {
                    
                    
                    @include media-breakpoint-up(xs) {
                        padding-top: 40px;
                    }

                    @include media-breakpoint-up(lg) {
                        padding-top: 20px;
                    }
                    
                    ._box-content {
                        
                        ._wrap-fields {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-start;
                            
                            @include media-breakpoint-up(xs) {
                                flex-direction: column;
                            }

                            @include media-breakpoint-up(lg) {
                                flex-direction: row;
                            }

                            .form-group,
                            ._wrap-form-select {
                                width: 100%;
                            }

                            ._wrap-form-select {
                                margin-left: 10px;
                                margin-top: -1px;
                                
                                @include media-breakpoint-up(xs) {
                                    margin-left: 0;
                                }

                                @include media-breakpoint-up(lg) {
                                    margin-left: 10px;
                                }
                            }
                            
                        }
                        
                        ._wrap-credit-card {
                            
                            border: 1px solid $color-ice-bold;
                            
                            ._wrap-title {
                                font-weight: $fw700;
                                font-size: 14px;
                                color: $color-black;
                                padding: 15px 10px;
                            }
                            
                            ._add-payment-accordion {

                                .card {

                                    .card-header {

                                        .btn-link {

                                            ._wrap-current-cc {

                                                ._delete-card {

                                                    display: none !important;
                                                    visibility: hidden !important;
                                                }

                                            }

                                        }

                                    }

                                }

                            }
                        }
                        
                    }
                    
                    .notice {
                        font-size: 12px;
                        font-weight: $fw700;
                        color: $color-gray;
                        margin-top: 20px;
                    }
                    
                    ._buttons {
                        .form-group-button {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                            text-align: left;
                            
                            @include media-breakpoint-up(xs) {
                                flex-direction: column;
                            }

                            @include media-breakpoint-up(lg) {
                                flex-direction: row;
                            }
                            
                            .btn-success {
                                
                                @include media-breakpoint-up(xs) {
                                    margin-bottom: 10px;
                                }

                                @include media-breakpoint-up(lg) {
                                    margin-bottom: 0;
                                }
                                
                            }
                            
                            .btn-cancel {
                                @include media-breakpoint-up(xs) {
                                    margin-left: 0;
                                }

                                @include media-breakpoint-up(lg) {
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
                    
                }
                
            }
            
            ._col._col-right {
                width: 100%;
                
                @include media-breakpoint-up(xs) {
                    margin-left: 0;
                }

                @include media-breakpoint-up(lg) {
                    margin-left: 15px;
                }
                
                .box {
                    
                    .resume-list {
                        
                        li {
                            
                            border-bottom: 1px solid $color-ice;
                            padding: 15px 0;
                            
                            &:last-child {
                                border-bottom: 0;
                                padding-bottom: 0;
                            }
                            
                            .resume-list-title {
                                font-size: 13px;
                                font-weight: $fw700;
                                color: $color-gray-bolder;
                                margin-bottom: 5px;
                            }
                            
                            .resume-list-value {
                                display: flex;
                                align-items: flex-end;
                                justify-content: space-between;
                                
                                ._title {
                                    color: $color-gray;
                                    text-transform: capitalize;
                                    font-size: 13px;
                                    font-weight: $fw600;
                                }
                                
                                ._value {
                                    font-size: 14px;
                                    color: $color-gray-bolder;
                                    font-weight: $fw700;
                                }
                                
                                ._value._price {
                                    font-size: 18px;
                                }
                            }
                        }
                        
                    }
                    
                }
            }
        }
        
    }
    
}