#view_official-stores {
    
    margin-bottom: 30px;
    
    ._med-container {
        
        ._wrap-help-search {
            
            max-width: 430px;
            width: $percent-100;
            margin: 0 auto;
            
            ._title {
                font-weight: $fw700;
                color: $color-black;
                text-align: center;
                line-height: 1.7;
                
                @include media-breakpoint-up(xs) {
                    font-size: 15px;
                    max-width: 220px;
                    margin: 0 auto;
                    margin-bottom: 10px;
                }

                @include media-breakpoint-up(md) {
                    max-width: none;
                    font-size: 18px;
                }
            }
        }
        
        ._wrap-stores-boxes {
            margin-top: 30px;

            ._title {
                font-weight: $fw400;
                color: $color-black;
                text-align: left;
                line-height: 1.7;
                margin-bottom: 10px;
                
                strong {
                    font-weight: $fw700;
                }
                
                @include media-breakpoint-up(xs) {
                    font-size: 14px;
                }

                @include media-breakpoint-up(md) {
                    max-width: none;
                    font-size: 16px;
                }
            }
        ._stores-boxes {

            margin-right: 15px;
            
                .col-6 {
                    padding-right: 0;
                    margin-bottom: 15px;

                    ._store-box {

                        background-color: $color-white;
                        -webkit-box-shadow: 0 4px 14px 4px rgba(180,180,180,0.1);
                        box-shadow: 0 4px 14px 4px rgba(180,180,180,0.1);
                        padding: 15px;
                        border-radius: 4px;
                        min-height: 124px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            height: auto;
                            
                            @include media-breakpoint-up(xs) {
                                width: 78%;
                            }
                            
                            @include media-breakpoint-up(lg) {
                                width: 34%;
                            }
                        }
                        
                    }
                }
            }
        }
        
        ._help-content {
            
            background-color: $color-white;
            border: 1px solid $color-ice;
            padding: 20px;
            
            ._title {
                font-weight: $fw700;
                color: $color-black;
                text-align: left;
                line-height: 1.7;
                
                @include media-breakpoint-up(xs) {
                    font-size: 15px;
                    max-width: 220px;
                    margin-bottom: 10px;
                }

                @include media-breakpoint-up(md) {
                    max-width: none;
                    font-size: 18px;
                }
            }
            
            ._wrap-topics {
                
                ._topics {
                        
                    ._topics-list {

                        li {
                            a {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                font-weight: $fw700;
                                color: $color-gray-bolder;
                                border-bottom: 1px solid $color-ice;
                                padding: 15px 0;
                                
                                ._item-name {
                                    
                                    @include media-breakpoint-up(xs) {
                                        font-size: 13px;
                                    }

                                    @include media-breakpoint-up(md) {
                                        font-size: 15px;
                                    }
                                    
                                }
                                
                                &::after {
                                    content: '';
                                    background-image: url('#{$path-image}/arrow-select-right.png');
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: 8px;
                                    height: 18px;
                                    width: 18px;
                                }
                                
                            }
                        }

                    }
                     
                }
                
            }
            
        }
        
    }
    
}