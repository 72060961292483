#view_activity-history,
#_my-wallet {

  ._title-box._with-breadbrumb {
    span {
      font-size: 12px;
      color: $color-gray;
      display: inline-block;
      margin-left: 5px;
    }
  }

  ._wrap-cards._flex {
    display: flex;
    align-items: flex-start;

    @include media-breakpoint-up(xs) {
      flex-direction: column;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  ._wrap-cards {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    .ui-card {
      .ui-card-content {
        .my-points {
          ._product-price {
            .ui-item__price {
              .price-tag-fraction {
                font-size: 24px;
                font-weight: $fw400;
                line-height: 1.4;
              }

              .price-tag-symbol {
                top: 3px;
              }
            }
          }
        }

        .points_informations {
          margin-top: 15px;

          ._list-info {
            li {
              border-bottom: 1px solid #f9f9f9;
              padding: 6px 0;
              display: flex;
              justify-content: space-between;

              @include media-breakpoint-up(xs) {
                align-items: flex-start;
                flex-direction: column;
              }

              @include media-breakpoint-up(md) {
                align-items: center;
                flex-direction: row;
              }

              &:first-child {
                border-top: 2px solid #f9f9f9;
              }

              .key,
              .val {
                font-size: 13px;
                font-weight: $fw700;
                color: $color-gray;
              }

              .val {
                color: $color-gray-bolder;
                @include media-breakpoint-up(xs) {
                  font-size: 11px;
                  margin-top: 5px;
                }

                @include media-breakpoint-up(md) {
                  font-size: 13px;
                }
              }
            }
          }
        }

        .add-points {
          margin-top: 30px;

          .add-points-title {
            font-size: 14px;
            font-weight: $fw700;
            color: $color-black;
          }

          .add-points-content {
            display: flex;
            margin-top: 15px;
            padding-bottom: 25px;

            @include media-breakpoint-up(xs) {
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
            }

            @include media-breakpoint-up(md) {
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            }

            .link-button {
              @include media-breakpoint-up(xs) {
                margin-top: 10px;
              }

              @include media-breakpoint-up(md) {
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    .ui-card.activity-history {
      .ui-card-content {
        padding-left: 0;
        padding-right: 0;

        // Accordion
        ._activity-history {
          .card {
            .card-header {
              button.btn {
                ._activity-history-info {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;

                  ._activity-date {
                    text-transform: uppercase;
                    font-size: 15px;
                    font-weight: $fw700;
                    color: $color-blue;
                    width: 80px;
                    height: auto;

                    .the-day {
                      display: block;
                      font-size: 12px;
                    }
                  }

                  ._activity-info {
                    color: $color-black;
                    font-weight: $fw700;
                    text-align: left;

                    ._activity-type {
                      font-size: 12px;
                      margin-bottom: 6px;

                      ._amount {
                        font-size: 14px;
                        span {
                          color: $color-blue;
                        }
                      }
                    }

                    ._activity-payment-type {
                      font-size: 12px;
                      margin-bottom: 3px;
                    }

                    ._activity-amount {
                      font-size: 14px;
                      color: $color-blue-light;
                      margin-bottom: 0px;
                    }

                    ._activity-status {
                      display: inline-block;
                      font-size: 10px;
                      color: $color-gray;
                      border-radius: 2px;
                      padding: 0.094rem 0.5rem;

                      svg {
                          width: 8px;
                          height: auto;
                          margin-left: 4px;
                      }
                    }

                    ._activity-status.pending {
                      svg {
                        path {
                          fill: $color-blue;
                        }
                      }
                    }

                    ._activity-status.approved {
                      svg {
                        path {
                          fill: $color-green;
                        }
                      }
                    }

                    ._activity-status.canceled {
                      svg {
                        path {
                          fill: $color-red;
                        }
                      }
                    }
                  }
                }

                ._activity-image {
                  width: 48px;
                  height: 48px;
                  overflow: hidden;
                  border-radius: 5px;
                  border: 1px solid $color-ice;

                  img {
                    height: auto;
                    width: 100%;
                  }
                }
              }
            }

            .collapse {
              .card-body {
                ._wrap-data-info {
                  margin-bottom: 20px;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  ._data-info-title {
                    font-weight: $fw700;
                    font-size: 13px;
                    color: $color-gray;
                  }

                  ._data-info-content {
                    font-weight: $fw700;
                    font-size: 13px;
                    color: $color-gray-bolder;
                    margin-top: 5px;
                  }
                }
              }
            }
          }
        }

        ._load-more-activities {
          border-top: 1px solid $color-ice-bold;
          text-align: center;
          padding: 15px 0;

          .link-button._load-more {
            border-radius: 4px;
          }
        }
      }
    }

    .ui-card._invite {
      .ui-card-content {
        padding-bottom: 25px;

        ._invite-label {
          .form-group {
            display: flex;

            .form-control {
              font-size: 13px;
              font-weight: $fw700;
              color: $color-black;
              border-right: 0;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }

            .btn {
              max-width: none;
              width: auto;
              border: 1px solid #eaeaea;
              border-left: 0px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;

              svg {
                width: 15px;
                height: auto;

                path {
                  fill: $color-gray;
                }
              }
            }
          }
        }

        ._guests-list {
          li {
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            .guests-info {
              color: $color-gray;
              font-size: 12px;
              font-weight: $fw700;
            }

            .guests-count {
              color: $color-black;
              font-size: 13px;
              display: inline-block;
              margin-left: 4px;
            }
          }
        }

        .link-button {
          margin-top: 15px;
        }
      }
    }

    .ui-card._cc {
      .ui-card-content {
        padding: 0;
      }
    }
  }
}

// UI CARD DEFAULTS
._wrap-cards {
  @include media-breakpoint-up(xs) {
    margin-right: 15px;
    margin-left: 15px;
  }

  @include media-breakpoint-up(md) {
    margin-right: 0;
    margin-left: 0;
  }

  .ui-card._medium {
    max-width: 370px;
    width: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(xs) {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .ui-card._fluid {
    max-width: 770px;
    width: 100%;
    margin: 0 auto;
  }
}

// RESET
#view_my-wallet {
  #view_my-account-layout {
    ._wrap-content {
      ._cols {
        ._col._col-right {
          border: 0;
          background-color: transparent;
        }
      }
    }
  }
}

//UI
.ui-card {
  border: 1px solid #f1f1f1;
  background-color: $color-white;

  .ui-card-head {
    padding: 15px 15px 15px 15px;
    .ui-card-title {
      font-size: 14px;
      font-weight: $fw700;
    }

    button.btn,
    a.btn {
      max-width: none;
      width: auto;
      padding: 0.169rem 0.275rem;

      svg {
        height: 16my-pointspx;
        width: auto;

        path {
          fill: $color-gray;
        }
      }
    }
  }

  .ui-card-head._with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ui-card-content {
    padding: {
      top: 0;
      bottom: 0;
      left: 15px;
      right: 15px;
    }
  }
}

._update-cc {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include media-breakpoint-up(xs) {
    flex-direction: column;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  ._update-cc-informations {
    max-width: 350px;
    width: 100%;

    ._update-cc-title {
      font-size: 14px;
      font-weight: $fw700;
      color: $color-gray-bolder;
    }

    ._payment-methods-list {
      margin-top: 20px;
      margin-bottom: 15px;
    }

    ._form {
      .form-group-button {
        margin: 0;

        button.btn {
          max-width: 360px;
        }
      }
    }
  }

  ._update-address {
    max-width: 350px;
    width: 100%;

    @include media-breakpoint-up(xs) {
      margin-top: 15px;
    }

    @include media-breakpoint-up(md) {
      margin-top: 72px;
      margin-left: 15px;
    }

    ._user-location {
      max-width: none;
    }

    .link-button {
      width: 100%;
      text-align: center;
      color: $color-white;
      border-color: $color-ice-bold;
      margin-top: 10px;
      font-size: 11px;
      padding: 0.313rem 0;
    }
  }
}

._wrap-current-cc {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include media-breakpoint-up(xs) {
    align-items: flex-start;
    flex-direction: column;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
  }

  ._current-cc-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ._cc-flag {
      text-align: center;

      ._cc-flag-icon {
        width: 40px;
        height: 26px;
        border-radius: 2px;
        border: 1px solid $color-ice;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        img {
          width: 22px;
          min-width: 22px;
          height: auto;
        }
      }

      ._tag {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 13px;
        width: 56px;
        background-color: $color-green;
        border-radius: 100px;
        font-size: 7px;
        font-weight: $fw700;
        color: $color-white;
        text-transform: capitalize;
        margin: 0 auto;
        margin-top: 4px;
      }
    }

    ._cc-info {
      margin-left: 10px;
      ._cc-number-prev,
      ._cc-exp-date,
      ._cc-name {
        color: $color-gray-bolder;
        font-weight: $fw700;
        font-size: 12px;
        text-align: left;
      }

      ._cc-exp-date {
        font-size: 10px;
      }

      ._cc-name {
        margin-top: 8px;
      }
    }
  }

  .btn._delete-card {
    font-size: 10px;
    font-weight: $fw700;
    color: $color-white !important;
    background-color: $color-red;
    padding: 0.094rem 0.58rem !important;
    border-radius: 4px;
    justify-content: center !important;
    max-width: 100px !important;

    @include media-breakpoint-up(xs) {
      margin-left: 68px !important;
      margin-top: 5px !important;
    }

    @include media-breakpoint-up(md) {
      margin-left: 0 !important;
      margin-top: 0 !important;
    }
  }
}

// Button

.btn-add-payment {
  background-color: #f9f9f9;
  font-size: 13px;
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: 700;
  color: $color-black;
  border: 0;

  @include media-breakpoint-up(xs) {
    max-width: 160px;
    font-size: 12px;
  }

  @include media-breakpoint-up(md) {
    max-width: none;
    font-size: 13px;
  }

  svg {
    margin-left: 10px;

    path {
      fill: $color-gray;
    }
  }
}