#_ui-fixed-menu--wrapper {
	
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: map-get($z-index, fixed-menu);
	-webkit-box-shadow: 0 -4px 10px 2px rgba(150,150,150,0.1);
	box-shadow: 0 -4px 10px 2px rgba(150,150,150,0.1);
	
	@include media-breakpoint-up(xs) {
		display: block;
	}
	
	@include media-breakpoint-up(lg) {
		display: none;
	}
	
	._ui-fixed-menu-items {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $color-blue;
		
		._ui-fixed-menu-item {
			background-color: $color-blue;
			width: 20%;
            height: 44px;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
			
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 28px;
				
				svg {
					width: 18px;
					height: auto;
					path { fill: $color-white; }
				}

				.message-box {
					margin-top: 4px;
				}
			}
			
			&:nth-child(1) { 
				
				svg {
					width: 21px;
				}
				
			}

			&:nth-child(2) { 
				
				svg {
					width: 18px;
				}
				
			}
			
			&:nth-child(3) { 
				
				a {
					position: relative;
					top: -8px;
					height: 40px;
					width: 40px;
					background-color: $color-red;
					border-radius: 100px;
					
					svg {
						path { fill: $color-white; }
					}
				}
				
			}
		}
		
		._ui-fixed-menu-item._active {
			
			position: relative;
			
			&::after {
				display: block;
				content: '';
				height: 2px;
				width: 100%;
				background-color: $color-red;
				position: absolute;
				left: 0;
				bottom: -1px;
			}
			
			a {

				svg {

					path {
						fill: $color-red;
					}

				}

			}

			&:nth-child(3) {
				a {
					svg {
						path { fill: $color-white; }
					}
				}
			}

		}
		
	}
	
}

#_ui-fixed-menu--wrapper2 {
	display: none;
}